import {BrowserModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SidebarComponent} from './views/sidebar/sidebar.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {LogoutComponent} from './views/logout/logout.component';
import {AppDataService} from './service/app_data.service';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {HomeComponent} from './views/home/home.component';
import {AutocompleteComponent} from './util/autocomplete/autocomplete.component';
import {NotificationComponent} from './views/notification/notification.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {RegisterDetailComponent} from './views/register-detail/register-detail.component';
import {UserComponent} from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import {MedicalAppointmentComponent} from './views/medical-appointment/medical-appointment.component';
import {MedicalAppointmentDetailComponent} from './views/medical-appointment-detail/medical-appointment-detail.component';
import {CardProfileComponent} from './views/components/card-profile/card-profile.component';
import {CompetenceDetailComponent} from './views/competence-detail/competence-detail.component';
import {SuggestionsDetailComponent} from './views/suggestions-detail/suggestions-detail.component';
import {BudgetEventComponent} from './views/budget-event/budget-event.component';
import {BudgetEventDetailComponent} from './views/budget-event-detail/budget-event-detail.component';
import {ProductComponent} from './views/product/product.component';
import {LineComponent} from './views/line/line.component';
import {CompetenceComponent} from './views/competence/competence.component';
import {GraphQLModule} from './graphql.module';
import {CURRENCY_MASK_CONFIG} from 'ng2-currency-mask';
import {SuggestionsComponent} from './views/suggestions/suggestions.component';
import { GoogleChartsModule } from 'angular-google-charts';
import {PaginationCustomModule} from './views/pagination-custom/pagination-custom.module';
import {NgxMaskModule} from 'ngx-mask';
import {ServiceBase} from "./base/service.base";

registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        SidebarComponent,
        LogoutComponent,
        HomeComponent,
        AutocompleteComponent,
        NotificationComponent,
        RegisterDetailComponent,
        UserComponent,
        AuthenticateComponent,
        MedicalAppointmentComponent,
        MedicalAppointmentDetailComponent,
        CardProfileComponent,
        CompetenceDetailComponent,
        SuggestionsComponent,
        SuggestionsDetailComponent,
        BudgetEventComponent,
        BudgetEventDetailComponent,
        ProductComponent,
        LineComponent,
        CompetenceComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        SlickCarouselModule,
        FormsModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgApexchartsModule,
        NgxPaginationModule,
        GraphQLModule,
        NgMultiSelectDropDownModule.forRoot(),
        GoogleChartsModule,
        PaginationCustomModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderHttpInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'BRL'
        },
        {
            provide: CURRENCY_MASK_CONFIG,
            useValue: {   // BRL
                align: 'left',
                allowNegative: true,
                decimal: ',',
                precision: 2,
                prefix: '$ ',
                suffix: '',
                thousands: '.'
            }
        },
        TranslateService,
        AppDataService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

import {Component, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    CreateLineGQL,
    RemoveLineGQL,
    SearchProductAll,
    SearchProductsAllGQL,
    SearchProductsAllQuery,
    SearchUserAllQuery,
    SearchProductLine,
    SearchProductLineGQL,
    SearchProductLineQuery,
    CreateProductLineInput,
    UpdateLineGQL,
    UpdateProductLineInput,
    User,
    Country,
    GetCountriesQuery,
    Unity,
    GetUnitiesQuery,
    SearchUserAll,
    ProductLine,
    UpdateUserInput,
    CreateUserInput,
    GetCountriesGQL,
    CreateProductCountryInput,
    CreateProductLineCountryInput,
    SearchProductLinePageGQL,
    ProductLinePage, SearchProductLinePageQuery, GetCountriesByUserGQL, GetCountriesByUserQuery
} from '../../../generated/graphql';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {QueryRef} from 'apollo-angular';
import {UserService} from '../../service/user.service';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-line',
    templateUrl: './line.component.html',
    styleUrls: ['./line.component.scss']
})
export class LineComponent extends BaseComponent implements OnInit, OnDestroy {

    user = new User();

    lines: ProductLinePage;

    linesQuery: QueryRef<SearchProductLinePageQuery>;

    filter = new SearchProductLine();

    model: UpdateProductLineInput = new UpdateProductLineInput();

    nameLine: string;

    labels: any = {
        // previousLabel: '<--',
        previousLabel: ' ',
        // nextLabel: '-->',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };


    countries: Array<Country>;
    countryQuery: QueryRef<GetCountriesQuery>;

    filterCountries: Country[] = [];

    selectedCountries: Country[] = [];

    countriesUser: Array<Country>;
    countryUserQuery: QueryRef<GetCountriesByUserQuery>;

    dropSettings: any = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Marcar todos',
        unSelectAllText: 'Desmarcar todos',
        itemsShowLimit: 4,
        allowSearchFilter: true
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public searchProductLinePageGQL: SearchProductLinePageGQL,
                public createLineGQL: CreateLineGQL,
                public updateLineGQL: UpdateLineGQL,
                public removeLineGQL: RemoveLineGQL,
                public userService: UserService,
                public getCountriesGQL: GetCountriesGQL,
                public getCountriesByUserGQL: GetCountriesByUserGQL,
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        // this.filter = {
        //     start: moment().startOf('day').subtract(30, 'days').utcOffset(0, true).format(),
        //     end: moment().endOf('day').utcOffset(0, true).format(),
        //     countries: []
        // };
        // this.setDatePicker('js-daterangepicker', (start, end, label) => {
        //     this.filter.start = start.utcOffset(0, true).format();
        //     this.filter.end = end.utcOffset(0, true).format();
        // });
        this.getLines();
        // this.getCountries();
        this.getCountriesByUser();
    }

    ngOnDestroy(): void {
        const elModalAddLine = $('#modalAddLine');
        if (elModalAddLine[0]) {
            elModalAddLine.remove();
        }
    }

    showModal(idModal) {
        $('#' + idModal).modal('show');
    }

    getLines() {
        this.filter.countries = this.filterCountries.map(e => e.id);
        this.linesQuery = this.searchProductLinePageGQL.watch({input: this.filter});
        this.linesQuery.valueChanges.subscribe(({data}) => {
            this.lines = data.searchProductLinePage as ProductLinePage;
        });
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.countries = data.countries as Array<Country>;
        });
    }

    getCountriesByUser() {
        this.countryUserQuery = this.getCountriesByUserGQL.watch();
        this.countryUserQuery.valueChanges.subscribe(({data}) => {
            this.countriesUser = data.countriesByUser as Array<Country>;
        });
    }

    searchLines(teste) {
        this.linesQuery.refetch(this.filter);
    }

    onFilter() {
        this.filter.countries = this.filterCountries.map(e => e.id);
        this.linesQuery.refetch({input: this.filter});
    }

    actionNew() {
        this.model = new ProductLine();
        this.selectedCountries = [];
        this.showModal('modalAddLine');
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: `${this.translate.instant('nome')}<br>`},
            {value: this.selectedCountries, text: `${this.translate.instant('pais')}<br>`},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }

        if (super.isNullOrUndefined(this.model.id)) {
            this.actionCreatedLine();
        } else {
            this.actionUpdateLine();
        }
    }

    actionEdit(item: ProductLine) {
        this.model = new UpdateProductLineInput();
        this.model.id = item.id;
        Object.assign(this.model, item);
        this.model.isVisible = item.isVisible;
        this.nameLine = this.model.name;
        this.selectedCountries = item.countries;
        this.showModal('modalAddLine');
    }

    actionCreatedLine() {
        const data = new CreateProductLineInput();
        data.name = this.model.name;
        data.isVisible = this.model.isVisible;
        data.countries = this.selectedCountries.map(e => {
            const a = new CreateProductLineCountryInput();
            a.id = e.id;
            return a;
        });

        this.createLineGQL.mutate({input: data}).subscribe(() => {
            this.model = new UpdateProductLineInput();
            this.linesQuery.refetch();
            this.closeModal('modalAddLine');
        });


    }

    actionUpdateLine() {
        const data = new UpdateProductLineInput();
        data.id = this.model.id;
        data.name = this.model.name;
        data.isVisible = this.model.isVisible;
        data.countries = this.selectedCountries.map(e => {
            const a = new CreateProductLineCountryInput();
            a.id = e.id;
            return a;
        });
        this.updateLineGQL.mutate({input: data}).subscribe(() => {
            this.model = new UpdateProductLineInput();
            this.linesQuery.refetch();
            this.closeModal('modalAddLine');
        });
    }

    actionDelete(userId) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningLineDelete'), () => {
            this.removeLineGQL.mutate({id: userId}).subscribe((result) => {
                this.linesQuery.refetch();
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningLineDeleteSuccess'), 'success');
            });
        });
    }

    pageChanged(event) {
        this.filter.page = event;
        this.onFilter();
    }

    onSelectDrop(item: any): void {
        /* const o = this.selectedOperations.find(sp => sp.id === item.id);
        this.setSelectedOperation(o); */
    }

    onDeselect(item: any): void {
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'medical-manager':
                return false;
            case 'district-manager':
                return false;
            case 'analyst-marketing':
                return false;
            case 'product-manager':
                return false;
            case 'marketing-manager':
                return false;
            case 'admin':
                return true;
            case 'admin-local':
                return true;
            case 'consult':
                return false;
        }
    }
}
